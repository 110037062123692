import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { ui } from 'reducers/ui'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import GlobalFonts from './fonts/fonts'
import { Nav } from './components/Nav'
import { Main } from './components/Main'

library.add(fab, faEnvelope)

const reducer = combineReducers({
  ui: ui.reducer
})

const store = configureStore({ reducer })

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route>
          <GlobalFonts />
          <Nav />
          <Main />
        </Route>
      </BrowserRouter>
    </Provider>
  )
}
