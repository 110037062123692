import styled from 'styled-components'

export const Button = styled.a`
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 32px;
  margin: ${(props) => props.margin};
  position: ${(props) => props.position};
  padding: 10px 28px;
  font-size: 16px;
  color: white;
  background: rgb(245,100,172);
  background: -moz-linear-gradient(22deg, rgba(245,100,172,1) 21%, rgba(106,156,194,1) 100%);
  background: -webkit-linear-gradient(22deg, rgba(245,100,172,1) 21%, rgba(106,156,194,1) 100%);
  background: linear-gradient(22deg, rgba(245,100,172,1) 21%, rgba(106,156,194,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f564ac",endColorstr="#6a9cc2",GradientType=1);
  z-index: 100;
  transition: 0.2s linear;

  &:hover {
    transform: scale(1.2);
`
