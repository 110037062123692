import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { PictureContainer, Content } from '../../lib/Containers'

const Pictures = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  width: 100%;
  padding: 0 20px;
 
  a{
    width: 100%;

    &:hover{
      img{
        transform: scale(1.1);
        z-index: 999;
      }
    }
  }

  img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .2s ease-in-out;
  }

  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a{
      display: flex;
      justify-content: center;
    }

    img{
      max-width: 50vw;
      margin: 5px;
    }
    }
`

export const Work = () => {
  const projects = useSelector((store) => store.ui.projects)

  return (
    <PictureContainer backgroundImage="url(https://storage.googleapis.com/bundora-7068e.appspot.com/3623807.jpg)" className="section" data-anchor="work">
      <Content>
        <div>
          <div className="slide">
            <Pictures>
              {projects.slice(0, 3).map((project, index) => (
                <a href={project.url} key={index} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <img src={project.src} alt="" />
                </a>
              ))}
            </Pictures>
          </div>
          <div className="slide">
            <Pictures>
              {projects.slice(3, 6).map((project, index) => (
                <a href={project.url} key={index} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <img src={project.src} alt="" />
                </a>
              ))}
            </Pictures>
          </div>
          <div className="slide">
            <Pictures>
              {projects.slice(6, 9).map((project, index) => (
                <a href={project.url} key={index} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <img src={project.src} alt="" />
                </a>
              ))}
            </Pictures>
          </div>
        </div>
      </Content>
    </PictureContainer>
  )
}
