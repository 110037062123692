import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Reveal, Tween } from 'react-gsap'
import { Content, SlantingContainer } from '../../lib/Containers'
import { SectionHeader, MediumHeader } from '../../lib/Text'

const SkillContainer = styled.article`   
    display: grid;
    width: 100%;
    grid-template-columns: repeat( auto-fit, minmax(500px, 1fr));
    grid-row-gap: 20px;
    align-items: center;
    padding: 20px;

    @media (max-width: 580px) {
      display: flex;
      flex-direction: column;
     
    }
`

const SkillItems = styled.ul`   
    display: flex;
    flex-direction: column;

    ul{
      margin: 2px 0 30px 0;

      li{
        display: inline-block;
        padding-right: 5px;
        color: var(--text-lighter);
      }
    }

    @media (max-width: 580px) {
      max-width: 100%;

      li{
        font-size: 14px;
      }
    }
`

export const Skills = () => {
  const skills = useSelector((store) => store.ui.skills)

  return (
    <SlantingContainer
      background="var(--main-color)"
      top="var(--main-color)"
      bottom="var(--main-color)"
      dataAnchor="skills"
      content={
        <Reveal>
          <Content position="relative">
            <SkillContainer>

              <SectionHeader>my skills include</SectionHeader>

              <SkillItems>
                <li>
                  <MediumHeader>Frontend Development</MediumHeader>
                  <Tween from={{ opacity: 0 }} duration={3}>
                    <ul>
                      {skills.frontend.map((skill, index) => (
                        <li key={index}>- {skill} </li>
                      ))}
                    </ul>
                  </Tween>
                </li>
                <li>
                  <MediumHeader>Backend Development</MediumHeader>
                  <Tween from={{ opacity: 0 }} duration={3}>
                    <ul>
                      {skills.backend.map((skill, index) => (
                        <li key={index}>- {skill} </li>
                      ))}
                    </ul>
                  </Tween>

                </li>
                <li>
                  <MediumHeader>Database Technologies</MediumHeader>
                  <Tween from={{ opacity: 0 }} duration={3}>
                    <ul>
                      {skills.databases.map((skill, index) => (
                        <li key={index}>- {skill} </li>
                      ))}
                    </ul>
                  </Tween>

                </li>
                <li>
                  <MediumHeader>DevOps and Deployment</MediumHeader>
                  <Tween from={{ opacity: 0 }} duration={3}>
                    <ul>
                      {skills.devops.map((skill, index) => (
                        <li key={index}>- {skill} </li>
                      ))}
                    </ul>
                  </Tween>

                </li>
                <li>
                  <MediumHeader>Other</MediumHeader>
                  <Tween from={{ opacity: 0 }} duration={3}>
                    <ul>
                      {skills.other.map((skill, index) => (
                        <li key={index}>- {skill} </li>
                      ))}
                    </ul>
                  </Tween>

                </li>
              </SkillItems>

            </SkillContainer>
            {/* <Button margin="auto">How can I help you?</Button> */}

          </Content>
        </Reveal>
      } />
  )
}
