import { createSlice } from '@reduxjs/toolkit'

export const ui = createSlice({
  name: 'ui',
  initialState: {
    instagram: [
      { url: 'https://www.instagram.com/nathaliecodes/', src: './images/instagram1.jpg' },
      { url: 'https://www.instagram.com/nathaliecodes/', src: './images/instagram2.jpg' },
      { url: 'https://www.instagram.com/nathaliecodes//', src: './images/instagram3.jpg' },
      { url: 'https://www.instagram.com/nathaliecodes/', src: './images/instagram4.jpg' }
    ],
    projects: [
      { url: 'https://apps.apple.com/se/app/hummy/id1601676552', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/hummy.png' },
      { url: 'https://apps.apple.com/se/app/m%C3%A4lart%C3%A5g/id1593657097', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/mdt.png' },
      { url: 'https://apps.apple.com/se/app/wear-iq/id1556851083', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/ssab.png' },
      { url: 'https://github.com/rautellin/project-eCommerce', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/ecommerce.png' },
      { url: 'https://github.com/rautellin/project-react-native-app', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/pomodoro.png' },
      { url: 'https://github.com/rautellin/project-movies', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/movies.png' },
      { url: 'https://github.com/rautellin/project-todos', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/workflow.png' },
      { url: 'https://github.com/rautellin/project-redux-quiz', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/meme.png' },
      { url: 'https://github.com/rautellin/project-happy-thoughts', src: 'https://storage.googleapis.com/bundora-7068e.appspot.com/Thoughts.png' }
    ],
    comments: [
      { name: 'Content manager', text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dicta quod facilis nemo nobis non culpa amet nulla eius esse? Assumenda!' },
      { name: 'Former manager', text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dicta quod facilis nemo nobis non culpa amet nulla eius esse? Assumenda!' }
    ],
    skills: {
      frontend: [
        'JavaScript (ES6+)',
        'React.js',
        'Vue.js',
        'Flutter',
        'Dart',
        'Swift'
      ],
      backend: [
        'Node.js',
        'Golang',
        'GRPC',
        'REST',
        'Protobuf',
        'Microservices',
        'Authentication and Authorization'
      ],
      databases: [
        'ArangoDB',
        'MongoDB',
        'Azure Cosmos DB',
        'Datastore',
        'Firebase'
      ],
      devops: [
        'Git',
        'Continuous Integration and Continuous Deployment (CI/CD)',
        'Terraform',
        'Bitrise',
        'Heroku',
        'Netlify',
        'Google Cloud Platform (GCP)',
        'Microsoft Azure'
      ],
      other: [
        'Agile Methodologies',
        'Test-Driven Development (TDD)',
        'Feature-Driven Development (FDD)',
        'Kafka',
        'Design systems',
        'Accessibility'
      ]
    }
  },
  reducers: {}
})
