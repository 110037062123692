import React from 'react';
import styled from 'styled-components';
import { ContactLink } from './ContactLink';

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
  margin: 2rem;
  
  h2 {
    font-weight: bold;
    color: var(--text);
    text-transform: uppercase;
    font-size: 22px;
  }
`;
export const ContactDetails = () => (
  <Section>
    <ContactLink icon={['fas', 'envelope']} text="say hello" href="mailto:nathalie@rautellinn.com" ariaLabel="Email" />
    <ContactLink icon={['fab', 'linkedin-in']} text="connect with me" href="https://www.linkedin.com/in/nathalierautell/" ariaLabel="Linkedin" />
    <ContactLink icon={['fab', 'github']} text="explore repos" href="https://github.com/rautellin" ariaLabel="Github" />
  </Section>
);
