import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Hamburger } from './nav/Hamburger'

const Container = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;

    @media (max-width: 830px) {
      padding: 20px 40px;
    }
`

const NavItems = styled.ul`
    display: flex;
    align-items: center;
    text-transform: uppercase;

    li{
      margin-left: 50px;
    }

    a {
      color: var(--text);
      font-weight: bold;
    }

    @media (max-width: 830px) {
      flex-direction: column;
      align-items: flex-end;
      background: rgba(252, 237, 242, 0.9);
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 100px 40px;
      width: 50vw;
      height: 100vh;
      transform: ${(props) => (props.transform ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)')};
      transition: all 0.5s ease-out;

      li{
        margin: 5px 0;
    }
    }
`

const Link = styled.a`
      position: relative;
      display: block;
      padding: 4px 0;
      font-family: Lato, sans-serif;
      color: #ecf0f1;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.5s;
      font-size: 16px;

      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        width: 100%;
        height: 3px;
        background: var(--darkest-pink);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
      }

      &:hover {
        color: var(--darkest-pink);
      }

      &.active {
        color: var(--darkest-pink);
      }

      &:hover::after, 
      &.active::after {
        transform: scaleX(1);
        transform-origin: left;
      }
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h1{
      font-family: 'Pejuang',serif;
      font-size: 64px;
      color: var(--text);
      margin-top: -20px;
    }

    p{
      text-transform: uppercase;
      margin-top: -10px;
      font-size: 12px;
      color: var(--text);
    }

    @media (max-width: 580px) {
      h1{
        font-size: 48px;
      }

      p{
      margin-top: -10px;
      font-size: 10px;
    }
    }
`

export const Nav = () => {
  const location = useLocation();
  const url = location.hash
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <a href="#home">
        <LogoContainer>
          <h1>Nathalie Rautell</h1>
          <p>creative fullstack developer</p>
        </LogoContainer>
      </a>
      <Hamburger open={open} setOpen={setOpen} />
      <NavItems transform={open} onClick={() => setOpen(!open)}>
        <li><Link href="#about" data-menuanchor="about" className={(url.includes('#about')) ? 'active' : null}>About</Link></li>
        <li><Link href="#work" data-menuanchor="work" className={(url.includes('#work')) ? 'active' : null}>Work</Link></li>
        <li><Link href="#skills" data-menuanchor="skills" className={(url.includes('#skills')) ? 'active' : null}>Skills</Link></li>
        <li><Link href="#contact" data-menuanchor="contact" className={(url.includes('#contact')) ? 'active' : null}>Contact</Link></li>
      </NavItems>
    </Container>
  )
}
