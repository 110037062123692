import React from 'react'
import { Tween } from 'react-gsap'
import styled from 'styled-components'
import { Button } from '../../lib/Buttons'

const Container = styled.div`
    background: url(https://storage.googleapis.com/bundora-7068e.appspot.com/3623849.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    ::before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: inherit;
      filter: blur(5px);
      -webkit-filter: blur(5px);
      transition: all 2s linear;
      -moz-transition: all 2s linear;
      -webkit-transition: all 2s linear;
      -o-transition: all 2s linear;
      background-color: rgba(255, 255, 255, .5);
    }

    /* --- MOBILE --- */
    @media (max-width: 580px) {
      background-image: url(https://storage.googleapis.com/bundora-7068e.appspot.com/img7.JPG);
      background-position: center;
      padding: 0;
    }
`

const Header = styled.div`
    h1{
      font-family: 'Pejuang',serif;
      font-size: 302px;
      margin: -40px 0;
    }

    span{
      position: absolute;
      top: 32px;
      color: var(--darkest-pink);
      z-index: var(--top-index);
      font-size: 122px;
    }

    @media (max-width: 580px) {
      h1{
        font-size: 152px;
        margin: -20px 0;
      }

      span{
        font-size: 82px;
        top: 0;
      }
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text);
    position: relative;
    z-index: var(--lower-index);
    padding: 0 40px;


    p{
      font-style: italic;
      font-size: 18px;
    }

      /* --- MOBILE --- */
      @media (max-width: 580px) {
      p{
        font-size: 16px;
      }
    }
`

export const Hero = () => {
  return (
    <Container className="section section1" data-anchor="home">
      <TextContainer>
        <Header>
          <h1>
            <Tween from={{ opacity: 1 }} to={{ opacity: 0 }} duration={1} delay={2}>
              <span>fullstack</span>
            </Tween>
            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={1} delay={3}>
              <span>creative</span>
            </Tween>
            developer
          </h1>
        </Header>
        <p>My happy place is creating beautifully polished designs and robust solutions.</p>
        <p>And I genuinely enjoy every bit of it.</p>
        <Tween from={{ opacity: 0 }} duration={1} delay={5}><Button href="#about" margin="20px 0">Get to know me</Button></Tween>
      </TextContainer>
    </Container>
  )
}
