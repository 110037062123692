import styled from 'styled-components'

export const SectionHeader = styled.h1`
font-weight: bold;
font-size: 102px;
font-family: 'Pejuang',serif;
color: var(--darkest-pink);
text-align: center;
margin: ${(props) => props.margin};
z-index: 100;

@media (max-width: 580px) {font-size: 62px;}
`

export const MediumHeader = styled.h2`
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--text);
  margin: ${(props) => props.margin};

@media (max-width: 580px) {font-size: 18px;}
`

export const BigParagraph = styled.p`
font-size: 34px;
font-weight: ${(props) => props.fontWeight || 'bold'};
color: ${(props) => props.color};
font-style: ${(props) => props.fontStyle};
text-align: ${(props) => props.align || 'center'};
`

export const SmallParagraph = styled.p`
font-size: 16px;
color: ${(props) => props.color};
line-height: 1.8;
text-align: ${(props) => props.align || 'center'};
font-style: ${(props) => props.fontStyle};
margin: ${(props) => props.margin};

@media (max-width: 580px) {font-size: 12px;}
`

