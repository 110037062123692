import React from 'react'
import styled from 'styled-components'
import { Reveal, Tween } from 'react-gsap'
import { Content, SlantingContainer } from '../../lib/Containers'
import { ContactDetails } from '../ContactDetails';
import { SectionHeader } from '../../lib/Text';

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const SlantingTop = styled.div`
  position: absolute;
  top: -50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100vw 0 0;
  border-color: ${(props) => props.borderColor};
  transform: scale(1.0001);
`

const ContactContainer = styled.div`
    position: relative;
    padding: ${(props) => props.padding};
    background: ${(props) => props.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a{
      margin-left: 10px;
      color: white;

      :hover{
        color: var(--text);
      }
    }

    div > a{
      font-size: 21px;
    }

    p, p a {
      padding-top: 10px;
      font-size: 12px;
      color: white;
      text-align: center;

      @media (max-width: 830px) {font-size: 10px;}
    }

    p a{
      color: white;
      margin: 0;

      :hover{
        color: var(--text);
      }
    }
`

const Companies = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p {display: block}

  img{
    filter: grayscale(100%);
    width: 100px;
    margin-right: 40px;
  }

  img:nth-child(4){
    width: 45px;
  }

  @media (max-width: 580px) {
    max-width: 100%;
    justify-content: space-between;
  img {
    width: 40px;
    margin-right: 0;
  }

  img:nth-child(4){
    width: 20px;
  }
}
`

export const Contact = () => {
  return (
    <SlantingContainer
      background="var(--main-color)"
      top="var(--main-color)"
      bottom="transparent"
      dataAnchor="contact"
      content={
        <Reveal>
          <Content>

            <div>
              <SectionHeader>my previous collaborations</SectionHeader>
              <Tween from={{ opacity: 0 }} duration={3}>
                <Companies>
                  <a href="https://www.tele2.se/" target="_blank" rel="noopener noreferrer" aria-label="Tele2">
                    <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/tele2-icon.png" alt="" />
                  </a>
                  <a href="https://www.spp.se/" target="_blank" rel="noopener noreferrer" aria-label="Spp">
                    <img src="https://www.spp.se/globalassets/startpage/spp_logo.png" alt="" />
                  </a>
                  <a href="https://www.nordea.se/" target="_blank" rel="noopener noreferrer" aria-label="Nordea">
                    <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/nordea-icon.png" alt="" />
                  </a>
                  <a href="https://www.telgeenergi.se/" target="_blank" rel="noopener noreferrer" aria-label="Telge">
                    <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/telge-icon.png" alt="" />
                  </a>
                  <a href="https://www.ikea.se/" target="_blank" rel="noopener noreferrer" aria-label="IKEA">
                    <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/ikea-logo.png" alt="" />
                  </a>
                  <a href="https://www.ssab.com/en" target="_blank" rel="noopener noreferrer" aria-label="SSAB">
                    <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/ssab-icon.png" alt="" />
                  </a>
                </Companies>
              </Tween>

            </div>

            <Footer>
              <ContactContainer background="var(--dark-gray)" padding="20px 0 30px 0">
                <SlantingTop borderColor="transparent var(--dark-gray) transparent transparent" padding="10px 0 20px 0" />
                <ContactDetails />
                <p>&copy; 2023 Nathalie Rautell, All rights reserved. Background images created by <a target="_blank" rel="noopener noreferrer" aria-label="Freepik" href="https://www.freepik.com/vectors/background">freepik</a></p>
              </ContactContainer>
            </Footer>

          </Content>
        </Reveal>
      } />
  )
}

