import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  position: relative;

  div {
    height: fit-content;
    width: fit-content;
    position: absolute;
    color: var(--text-light);
    opacity: 0;
    text-transform: uppercase;
    visibility: hidden;
    transition: 0.2s linear;
    top: 115%;
    white-space: nowrap;
    
    p {
      font-weight: bold;
      padding: 0;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    transition: 0.2s linear;
  }

  .icon path {
    fill: white;
    transition: 0.2s linear;
  }

  &:hover > .icon {
    transform: scale(1.2);
  }

  &:hover div {
    visibility: visible;
    opacity: 1;
    top: 105%;
  }
`;

export const ContactLink = ({ text, icon, href, ariaLabel }) => {
  return (
    <Link href={href} aria-label={ariaLabel} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} className="icon" />
      <div className="text"><p>{text}</p></div>
    </Link>
  );
};
