import { createGlobalStyle } from 'styled-components'

import aurothesiaWoff from './aurothesia.woff'
import aurothesiaWoff2 from './aurothesia.woff2'
import barnettWoff from './barnett.woff'
import barnettWoff2 from './barnett.woff2'
import eamolkoWoff from './eamolko.woff'
import eamolkoWoff2 from './eamolko.woff2'
import pejuangWoff from './pejuang.woff'
import pejuangWoff2 from './pejuang.woff2'

export default createGlobalStyle`
    @font-face {
        font-family: 'Aurothesia';
        src: local('Aurothesia'), local('Aurothesia'),
        url(${aurothesiaWoff2}) format('woff2'),
        url(${aurothesiaWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Barnett';
        src: local('Barnett'), local('Barnett'),
        url(${barnettWoff2}) format('woff2'),
        url(${barnettWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Eamolko';
        src: local('Eamolko'), local('Eamolko'),
        url(${eamolkoWoff2}) format('woff2'),
        url(${eamolkoWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Pejuang';
        src: local('Pejuang'), local('Pejuang'),
        url(${pejuangWoff2}) format('woff2'),
        url(${pejuangWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;

