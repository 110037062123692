import React from 'react'
import styled from 'styled-components'

export const Content = styled.div`  
    margin: auto;
    max-height: calc(100vh - 200px);
    max-width: 1220px;
    position: ${(props) => props.position};
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;

    @media (max-width: 830px) {
      max-height: calc(100vh - 120px);
      max-width: calc(100vw - 80px);
    }
`

export const PictureContainer = styled.section`   
    width: 100vw;
    background: ${(props) => props.backgroundImage};
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    z-index: var(--lower-index);

    ::before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: inherit;
      z-index: -1;
      filter: blur(5px);
      -moz-filter: blur(5px);
      -webkit-filter: blur(5px);
      -o-filter: blur(5px);
      transition: all 2s linear;
      -moz-transition: all 2s linear;
      -webkit-transition: all 2s linear;
      -o-transition: all 2s linear;
      background-color: rgba(255, 255, 255, .5);
    }
`

const SlantingMain = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: var( --medium-index);
`

const SlantingTop = styled.div`
  position: absolute;
  top: -50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100vw 0 0;
  border-color: transparent ${(props) => props.background} transparent transparent;
  transform: scale(1.0001);
  z-index: var( --medium-index);
`

const SlantingBottom = styled.div`
  position: absolute;
  z-index: var( --medium-index);
  bottom: -50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100vw 0 0;
  border-color: ${(props) => props.background} transparent transparent transparent;
  transform: scale(1.0001);
`

export const SlantingContainer = (props) => {
  return (
    <SlantingMain background={props.background} className="section" data-anchor={props.dataAnchor}>
      <SlantingTop background={props.top} />
      {props.content}
      <SlantingBottom background={props.bottom} />
    </SlantingMain>
  )
}
