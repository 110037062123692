import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Hero } from './main/Hero'
import { About } from './main/About'
import { Work } from './main/Work'
import { Skills } from './main/Skills'
import { Contact } from './main/Contact'
import { Misc } from './main/Misc'

export const Main = () => {
  return (
    <ReactFullpage
      navigation
      render={({ state, fullpageApi }) => {
        return (
          <div id="fullpage-wrapper">
            <Hero />
            <About />
            <Work />
            <Skills />
            <Misc />
            <Contact />
          </div>
        );
      }} />
  );
}
