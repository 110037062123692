import React from 'react'
import styled from 'styled-components'
import { Reveal, Tween } from 'react-gsap'
import { Content, PictureContainer } from '../../lib/Containers'

const Images = styled.div`
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  max-width: 830px;
  width: 100%;
  z-index: var(--medium-index);

  img{
    max-height: 60vh;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 580px) {
    display: flex;

    img{
      max-height: 80vh;
      padding: 20px;
    }

    img:nth-child(2){
      display: none;
    }
  }
`

const Quote = styled.p`
font-size: 22px;
position: absolute;
color: var(--text);
max-width: 830px;
text-align: center;
line-height: 20px;
z-index: var(--top-index);

@media (max-width: 830px) {  
      max-width: 80vw; 
      font-size: 22px;
      line-height: 25px;
}

@media (max-width: 580px) {
  position: static;
  padding: 10px;
  font-size: 14px;
  line-height: 25px;
  }
`

const Cursive = styled.span`
font-family: 'Pejuang',serif;
font-size: 90px;
padding: 0 2px;
position: relative;
top: 12px;
display: inline-block;
margin-top: -5px;
font-weight: bold;
color: var(--darkest-pink);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

@media (max-width: 830px) {  
  top: 9px;
  font-size: 60px;
}

@media (max-width: 580px) {
  top: 4px;
  margin-top: -25px;
  font-size: 35px;
  }
`

export const Misc = () => {
  return (

    <PictureContainer backgroundImage="url(https://storage.googleapis.com/bundora-7068e.appspot.com/3623813.jpg)" className="section" data-anchor="misc">
      <Reveal>
        <Content position="relative">
          <Images aria-label="Pictures of Nathalie">
            <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/img6.jpg" alt="Nathalie looking down" />
            <img src="https://storage.googleapis.com/bundora-7068e.appspot.com/img7.JPG" alt="Nathalie looking to the left" />
          </Images>
          <Tween from={{ opacity: 0 }} duration={3}>
            <Quote>Picture a world where development is an art form and software solutions are just colorful canvases. There you will find me bringing that world to life as a <Cursive>creative developer </Cursive></Quote>
          </Tween>
        </Content>
      </Reveal>
    </PictureContainer>

  )
}
