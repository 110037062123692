import React from 'react'
import { Reveal, Tween } from 'react-gsap'
import styled from 'styled-components'
import { Content, SlantingContainer } from '../../lib/Containers'
import { SmallParagraph, SectionHeader } from '../../lib/Text'
import { Button } from '../../lib/Buttons'

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
`

const Image = styled.img`
object-fit: cover;
max-width: 35%;
max-height: calc(100vh - 200px);
flex: 1;

@media (max-width: 830px) {
  max-width: 50%;
}

@media (max-width: 580px) {
  max-width: 100%;
}
`

const TextContainer = styled.div`
width: 50%;
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0 40px;

h1, a{
  display: block;
  margin: 10px 0;
}

@media (max-width: 580px) {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--main-color);
}
`

const Text = styled.div`
p{
  text-align: justify;
  margin-bottom: 10px;
}
  

@media (max-width: 830px) {  
  p{
    font-size: 14px;
  }
}

@media (max-width: 580px) {  
  p{
    font-size: 12px;
  }
}
`

const GIF = styled.img`
  display: inline-block;
  width: 25px;
 `

export const About = () => {
  return (
    <SlantingContainer
      background="var(--main-color)"
      top="var(--main-color)"
      bottom="var(--main-color)"
      dataAnchor="about"
      content={
        <Reveal>
          <Content>
            <Container>

              <Image src="https://storage.googleapis.com/bundora-7068e.appspot.com/about.jpg" />

              <Tween from={{ opacity: 0 }} duration={(window.innerWidth > 580) ? 3 : 2} delay={(window.innerWidth > 580) ? 0 : 1}>
                <TextContainer>
                  <SectionHeader>meet Nathalie</SectionHeader>
                  <Text>
                    <SmallParagraph><span>I&apos;m a cat lover, gymrat and low key yogini (I know my limits). My closest friends call me wikinat because of my insatiable curiosity and knack for knowing random facts.</span></SmallParagraph>
                    <SmallParagraph><span>I exist in two parallel dimensions: one where I immerse myself in the world of coding, diving deep into lines of logic and algorithms for hours on end, and the other where I unleash my inner hulk, pretending to be stronger than I actually am with sweat and stubborn determination. It&apos;s like living in a quirky time warp where bytes and dumbbells coexist harmoniously.</span></SmallParagraph>
                    <SmallParagraph><span>Behind my energetic and playful exterior, lies a heart filled with empathy and care for those around me. I wholeheartedly believe in the power of kindness, and there&apos;s no such thing as too much of either!</span></SmallParagraph>
                    <SmallParagraph><span>Another thing you&apos;ll quickly notice about me is my undying love for communicating through GIFs. Why rely on dull words when you can express yourself through random animated images? <GIF src="https://storage.googleapis.com/bundora-7068e.appspot.com/kitten.gif" alt="Cat GIF" width="45px" /></span></SmallParagraph>
                  </Text>
                  <Button href="#contact">Contact me</Button>
                </TextContainer>
              </Tween>

            </Container>
          </Content>
        </Reveal>
      } />
  )
}
