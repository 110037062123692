import React from 'react'
import styled from 'styled-components'

const MenuBtn = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 999;
  display: none;
  
  @media (max-width: 830px) {
    display: inline-block;
  }
`

const BtnLine = styled.div`
  display: block;
  height: 2px;
  transition: all .5s ease-in;
  width: 18px;
  background: ${(props) => (props.background ? 'transparent' : 'var(--text)')};

  ::before, ::after{
    background: var(--text);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .5s ease-out;
    width: 100%;
  }

  ::before{
    top: 5px;
    transform: ${(props) => (props.transform ? 'translateY(-5px) rotate(-135deg)' : null)};
  }

  ::after{
    top: -5px;
    transform: ${(props) => (props.transform ? 'translateY(5px) rotate(135deg)' : null)};
  }
`

export const Hamburger = ({ open, setOpen }) => {
  return (
    <MenuBtn onClick={() => setOpen(!open)}>
      <BtnLine transform={open} background={open} />
    </MenuBtn>
  )
}
